const React = require("react");
const { default: Layout } = require("./src/layouts");
const GlobalContextProvider =
  require("./src/context/globalContextProvider").default;
require("./src/styles/global/global.scss");

exports.wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

exports.wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}>{element}</Layout>;
};
