import * as React from "react";
import { useState } from "react";
import * as styles from "./loader.module.scss";

const Loader = (props) => {
  if (props.location === "liverpool") {
    var logo = "/liverpool/flannels-logo.png";
    var image = "/liverpool/store-overview.png";
  }

  const [firstScene, setFirstScene] = useState(true);
  const [firstSceneImage, setFirstSceneImage] = useState(false);
  const [secondScene, setSecondScene] = useState(false);

  if (firstScene === true) {
    setTimeout(function () {
      setFirstScene(false);
    }, 3000);
  }

  if (secondScene === false) {
    setTimeout(function () {
      setSecondScene(true);
    }, 3000);
  }

  if (firstSceneImage === false) {
    setTimeout(function () {
      setFirstSceneImage(true);
    }, 1000);
  }

  return (
    <div className={`${styles.loader} ${props.active ? styles.active : ""}`}>
      <div
        className={`${styles.firstscene} ${firstScene ? styles.active : ""}`}
      >
        <img
          className={`${styles.first} ${firstSceneImage ? styles.active : ""}`}
          src={logo}
          alt="Flannels logo"
        />
      </div>
      <div
        className={`${styles.secondscene} ${secondScene ? styles.active : ""}`}
      >
        <img className={styles.second} src={image} alt="Exploded Floorplan" />
      </div>
    </div>
  );
};

export default Loader;
