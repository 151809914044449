exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-liverpool-index-js": () => import("./../../../src/pages/liverpool/index.js" /* webpackChunkName: "component---src-pages-liverpool-index-js" */),
  "component---src-pages-liverpool-level-1-js": () => import("./../../../src/pages/liverpool/level-1.js" /* webpackChunkName: "component---src-pages-liverpool-level-1-js" */),
  "component---src-pages-liverpool-level-2-js": () => import("./../../../src/pages/liverpool/level-2.js" /* webpackChunkName: "component---src-pages-liverpool-level-2-js" */),
  "component---src-pages-liverpool-level-3-js": () => import("./../../../src/pages/liverpool/level-3.js" /* webpackChunkName: "component---src-pages-liverpool-level-3-js" */),
  "component---src-pages-liverpool-level-4-js": () => import("./../../../src/pages/liverpool/level-4.js" /* webpackChunkName: "component---src-pages-liverpool-level-4-js" */),
  "component---src-pages-liverpool-level-5-js": () => import("./../../../src/pages/liverpool/level-5.js" /* webpackChunkName: "component---src-pages-liverpool-level-5-js" */),
  "component---src-pages-liverpool-level-6-js": () => import("./../../../src/pages/liverpool/level-6.js" /* webpackChunkName: "component---src-pages-liverpool-level-6-js" */)
}

